import React, { useEffect, useRef, useState } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"

function VideoPlayer(props) {
  const [videoRef, setVideoRef] = useState(useRef(null))
  const [player, setPlayer] = useState()

  useEffect(() => {
    // instantiate Video.js

    const player = videojs(videoRef.current, props, function onPlayerReady() {})

    setPlayer(player)

    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [])

  // TODO Do I need to update the component?
  // componentWillReceiveProps(newProps) {
  //   // When a user moves from one title to the next, the VideoPlayer component will not be unmounted,
  //   // instead its properties will be updated with the details of the new video. In this case,
  //   // we can update the src of the existing player with the new video URL.
  //   if (this.player) {
  //     this.player.src({
  //       type: newProps.video.mime_type,
  //       src: newProps.video.video_url
  //     });
  //   }
  // }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  // use `ref` to give Video JS a reference to the video DOM element: https://reactjs.org/docs/refs-and-the-dom
  return (
    <>
      <div data-vjs-player>
        <video
          ref={node => (videoRef.current = node)}
          className="video-js"
        ></video>
      </div>
    </>
  )
}

export default VideoPlayer
